//import axios from 'axios';
import api from './api';

//const API_URL = 'http://192.168.0.88:8080/api/auth/';
//const API_URL = 'http://127.0.0.1:8080/api/auth/';

class AuthService {
  login(user) {
    return api
      .post('auth/signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return api.post('auth/signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      firstname: user.firstname,
      lastname: user.lastname
    });
  }

  resetPassword(emailaddress) {
    return api.post('auth/sendresetpasswordmail', {
      email: emailaddress
    });
  }

  finalisePasswordReset(password, token) {
    return api.post('auth/resetpassword', {
      password: password,
      token: token
    });
  }
}

export default new AuthService();