<template>
  <div id="app" style="min-height: 100%">
    <v-app id="ukpl" class="cppsbg">
      <v-container>
        <router-view />
      </v-container>
    </v-app>
  </div>
</template>

<style>
/* See assets main.css */
</style>

<script>
export default {
  name: "App",

  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
  },
};
</script>
