import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/profile',
    name: 'profile',
    // lazy-loaded
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    // lazy-loaded
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/roster',
    name: 'roster',
    // lazy-loaded
    component: () => import('../views/Roster.vue')
  },
  {
    path: '/team',
    name: 'team',
    // lazy-loaded
    component: () => import('../views/Team.vue')
  },
  {
    path: '/idcard',
    name: 'idcard',
    // lazy-loaded
    component: () => import('../views/IdCard.vue')
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    // lazy-loaded
    component: () => import('../views/Disclaimer.vue')
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    // lazy-loaded
    component: () => import('../views/ChangePassword.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? "/" : "",
  routes
})

export default router
