import axiosInstance from "./api";
import TokenService from "./token.service";
import router from "../router";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      console.log(originalConfig);
      if (originalConfig.url !== "/auth/signin" && err.response) {
        console.log(err.response);
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
        //   originalConfig._retry = true;
        //   try {
        //     const rs = await axiosInstance.post("/auth/refreshtoken", {
        //       refreshToken: TokenService.getLocalRefreshToken(),
        //     });
        //     const { accessToken } = rs.data;
        //     store.dispatch('auth/refreshToken', accessToken);
        //     TokenService.updateLocalAccessToken(accessToken);
        //     return axiosInstance(originalConfig);
        //   } catch (_error) {
        //     return Promise.reject(_error);
        //   }
        // } else if (err.response.status === 401) {
            store.dispatch("auth/logout");
            router.push("/");
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;